import { css } from 'styled-components';

export default css`
    box-sizing: border-box;
    padding: 10px 13px;
    margin-bottom: 30px;
    width: 100%;
    border: 2px solid #cacaca;
    border-radius: 6px;
    background: #fef4f7;
    color: #28282f;
    font-size: 13px;
    font-family: 'Ubuntu Mono', monospace;
`;
